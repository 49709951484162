import type { PortfolioItemState } from '@/components/DeprecatedPortfolios/redux/reducers/interface';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Floats and integers only
 *
 * ".0"          => false
 * "0.000"       => true
 * "0,000"       => true
 * "123"         => true
 * "123.001"     => true
 * "...0abc."    => false
 * "abc0.]][]1"  => false
 * "    0.   1"  => false
 */
const numberInputRegExp = /^([0-9]+([.,][0-9]*)?|[.,][0-9]+)$/;

export const validateFirstname = (value: string) => {
  const validator = new RegExp(/^([^0-9]*)$/); //No numbers allowed
  return value.length > 1 && value.length < 253 && validator.test(value);
};
export const validateDisplayName = (value: string): boolean => {
  // Check if the display name is not empty
  if (value.length < 3) {
    throw new Error('Display name must have a minimum length of 3 characters.');
  }

  // Check if the display name exceeds the maximum length
  if (value.length > 30) {
    throw new Error(
      'Display name must have a maximum length of 30 characters.'
    );
  }

  // Check if the display name contains only allowed characters
  if (!/^[a-zA-Z0-9_]+$/.test(value)) {
    throw new Error(
      'Display name can only contain alphanumeric characters and underscores.'
    );
  }

  return true;
};
export const validateEmailAddress = (value: string) =>
  emailRegExp.test(value) && value.length < 253;

export const validateExistingPortfolioName = (
  name: string,
  existingName: string,
  keys: string[] = [],
  entities: Record<string, PortfolioItemState>
) => {
  if (!name) {
    return 'Please enter a name';
  } else if (name.length <= 1) {
    return 'Please enter a name greater than 1 characters';
  } else if (name.length > 254) {
    return 'Please enter a name less than 255 characters';
  } else if (
    name !== existingName &&
    keys &&
    keys.findIndex(
      (n) => entities[n].name.toLowerCase() === name.toLowerCase()
    ) > -1
  ) {
    return 'You have a portfolio already with this name';
  }
  return '';
};

export const validateNewPortfolioName = (
  name: string,
  names: string[] = []
) => {
  try {
    if (!name) {
      return 'Please enter a name';
    } else if (name.length <= 1) {
      return 'Please enter a name greater than 1 character';
    } else if (name.length > 254) {
      return 'Please enter a name less than 255 characters';
    } else if (
      names.map((name) => name.toLowerCase()).includes(name.toLowerCase())
    ) {
      return 'You have a portfolio already with this name';
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const isValidNumberInput = (value: string) =>
  numberInputRegExp.test(value);
