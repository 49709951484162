import styled from 'styled-components';
import { RouterLink } from '@redux-first-router-link';
import { LoadingSpinner } from '@simplywallst/ui-core';
import CheckIconSVG from '@assets/images/icons/legacy/check.svg';

export const FieldWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.x3space}rem;
`;

export const ForgotPassword = styled(RouterLink)`
  ${({ theme }) => theme.typography.tiny}
  margin-top: ${({ theme }) => theme.x1spacePx}px;
  display: block;
  text-align: right;
`;

export const Spinner = styled(LoadingSpinner)`
  margin-top: 12px;
`;

export const Valid = styled(CheckIconSVG)`
  width: 14px;
`;
